@charset "UTF-8";
@import "variables";


/* ::::::::::  404  :::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

html,
body {
  height: 100%;
}
body {
  background: $gray;
}

/* =========================================================
notFound
========================================================= */
.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-weight: bold;
  @include sp {
    .wrapper {
      width: 90%;
      margin: 0 auto;
    }
  }
  h1 {
    margin-bottom: 35px;
    @include sp {
      margin-bottom: 25px;
    }
    img {
      width: 394px;
      @include sp {
        width: 260px;
      }
    }
  }
  h2 {
    margin-bottom: 25px;
    font-size: 2.1rem;
    @include sp {
      margin-bottom: 15px;
    }
  }
  .btn {
    max-width: 340px;
    margin: 30px auto;
    @include sp {
      margin: 20px 0;
    }
    a {
      font-size: 1.5rem;
      text-decoration: underline;
      @include sp {
        width: 100%;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

