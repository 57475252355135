@charset "UTF-8";

//ベースカラー
$navy: #222f53;
$gray: #f5f6f6;
$gray2: #888888;

$font_color: #7b8187;
$link_color: #121b27;
$bd_color: #7b8187;
$bd_color2: #bfbfbf;

//フォント
$base_fontsize: 14px;
$base_font: YuGothic, "游ゴシック", Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, "Droid Sans", Meiryo, "メイリオ", Verdana, sans-serif;
$mincho: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;

$english: "Work Sans", Helvetica, Arial, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;


//ベースサイズ
$base_width: 1020px;  
$base_margin: calc( (100% - 1020px) / 2 );


@mixin icon_arrow ($left: auto,$right: 10px, $rotate: -45deg) {
	content:'';
	position: absolute;
	top: 0;
	bottom: 0;
	left: $left;
	right: $right;
	z-index: 0;
	border-right: 1px solid;
	border-bottom: 1px solid;
  margin: auto;
	transform: rotate($rotate);
}

@mixin english {
  font-family: $english;
  font-weight: 500;
}


@mixin pc {
	@media (min-width: 1000px) {
		@content;
  }
}
@mixin tab {
  @media (max-width: 1150px) {
    @content;
  }
}
@mixin sp {
	@media (max-width: 999px) {
		@content;
  }
}
@mixin media ($width: 1000px) {
	@media screen and (min-width: $width) {
		@content;
  }
}
